type ExtraFetchOptions = {
  gateway?: "web" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
};

const getUserAgent = (): string => {
  if (typeof window === "undefined") {
    const headers = useRequestHeaders();
    return headers["user-agent"] || "";
  }
  return window.navigator.userAgent;
};

const getPlatformVersion = (): string => {
  if (typeof window === "undefined") {
    return "omit empty";
  }
  const browserInfo = getBrowserInfo();
  return `${browserInfo.os} ${browserInfo.browser} ${browserInfo.version}`;
};

export default defineNuxtPlugin(() => {
  // эти штуки тут берём один раз в замыкание, чтобы на каждую отправку их не высчитывать
  // т.к. они практически не меняются
  // на SSR это тоже проблем вызвать не должно, т.к. набор плагинов на каждый входящий запрос инициализируется свой
  const userAgent = getUserAgent();
  const platformVersion = getPlatformVersion();
  const appVersion = useRuntimeConfig().public.version || "";
  const authStore = useAuthStore();

  const $transport = $fetch.create({
    async onRequest({ options }) {
      const gateway = (options as ExtraFetchOptions).gateway ?? "web";
      const deviceId =
        useCookie(APP_COOKIE_STORAGE_KEYS.uniqDeviceID).value || "omit empty";
      const ksid = Cookie.get(APP_COOKIE_STORAGE_KEYS.ksid) || "disabled";
      options.baseURL = gateway === "web" ? "/webgate" : "/magnit-id";
      const getAuthorizationToken = async () => {
        const AccessJWT = await authStore.fetchJWTAccessToken();
        if (!AccessJWT) throw new Error("Отсутствует JWT токен");
        return `Bearer ${AccessJWT}`;
      };
      const headers = new Headers({
        [HEADERS_CONTENT_TYPE]: "application/json",
        [HEADERS_USER_AGENT]: userAgent,
        [HEADERS_PLATFORM_VERSION]: platformVersion,
        [HEADERS_DEVICE_TAG]: ksid,
        [HEADERS_FINGERPRINT]: deviceId,
        [HEADERS_APP_VERSION]: appVersion,
        [HEADERS_DEVISE_PLATFORM]: "Web",
        [HEADERS_NEW_MAGNIT]: "true",
        [HEADERS_CLIENT_NAME]: gateway === "web" ? "cosmetic" : "",
      });
      if ((options as ExtraFetchOptions).permissions?.jwt) {
        const Authorization = await getAuthorizationToken();
        headers.set(HEADERS_AUTHORIZATION, Authorization);
      }
      // Для FormData не нужно явно задавать заголовок типа, ofetch сам его выведет и сделает multipart/form-data с нужным boundary
      // явно передадите content-type -- будет неверная отправка, т.к. boundary не будет вычислен
      if (options.body instanceof FormData) {
        headers.delete(HEADERS_CONTENT_TYPE);
      }
      // Правильно "спредить" заголовки хитрее чем кажется,
      // наивный спред не всегда сработает,
      // т.к. {...new Headers({'my-header': 'hello world'})} это будет не то что вы могли ожидать
      if (options.headers) {
        for (const [name, value] of Object.entries(
          options.headers,
        )) {
          headers.set(name, value);
        }
      }
      options.headers = headers;
    },
    onRequestError(context) {
      console.error("request error", context.request, context.error);
    },
    onResponseError(context) {
      console.error(
        "response error",
        context.request,
        toValue(context.response.status),
        toValue(context.response._data.message),
      );
    },
  });

  return {
    provide: {
      transport: $transport,
    },
  };
});
